<template>
  <div class="container">
    <van-nav-bar 
      title="中奖记录" 
      fixed
      placeholder
      @click-left="onClickLeft" 
      left-arrow />

    <p style="height: 15px;"></p>

    <div class="container-kong" v-if="!list.length">
      <img class="container-kong-img" src="./img/kong.png" alt="">
    </div>
    
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad">
      <div class="recordsList" v-for="x in list" :key="x.id">
        <van-image
          width="40"
          height="40"
          fit="cover"
          :src="x.prizeImg"/>

        <div class="recordsList-grade recordsList-item">
          <p class="recordsList-grade-jaing">{{ x.name }}</p>
          <p class="recordsList-grade-jin">{{ x.prizeInfo }}</p>
        </div>

        <div class="recordsList-time recordsList-item">
          <p class="recordsList-time-date">{{ x.createTime }}</p>
          <span></span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>

import { getJiangliList } from '@/api/Ge/port'

export default {
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      formInline: {
        current: 1,
        size: 10
      }
    }
  },

  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },

    onLoad () {
      this.getPage()
      this.formInline.current++
    },

    getPage () {
      getJiangliList(this.formInline).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          res.data.data.records.forEach(x => {
            let typeName = ''
            let typeJine = ''
            switch (x.prize) {
              case '1': typeName = '优惠券', typeJine = x.prizeInfo; break
              case '2': typeName = '现金', typeJine = x.prizeInfo + '元'; break
              case '3': typeName = '驿马', typeJine = x.prizeInfo + '匹'; break
              case '4': typeName = '金叶子', typeJine = x.prizeInfo + '个'; break
              case '5': typeName = '官凭', typeJine = x.prizeInfo + '个'; break
              case '6': typeName = '商品', typeJine = x.prizeInfo; break
              case '7': typeName = '再接再厉'; break
            }
            x.typeName = typeName
            x.typeJine = typeJine
          })
          if (res.data.data.records.length) {
            this.list = [...this.list, ...res.data.data.records]
          } else {
            this.finished = true
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0;
}
.container {
  background: #F6F6F6;
  min-height: 100%;
  padding-bottom: 10px;
  &-kong {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    &-img {
      width: 50%;
    }
  }
}
.recordsList {
  width: 95%;
  margin: 0 auto 10px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  display: flex;
  align-content: stretch;
  &-item {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-grade {
    flex: 1;
    margin-left: 10px;
    &-jaing {
      font-size: 15px;
    }
    &-jin {
      color: #333;
    }
  }
  &-time {
    &-date {
      color: #999;
    }
  }
}
</style>